<template>
  <div class="page-wrapper assignPlan-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Assign Plan To Rep</h1>
      </div>
      <div>
        <down-line-rep-plan-select-card-list
            :repId="repId"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DownLineRepPlanSelectCardList from "./plans/DownLineRepPlanSelectCardList";
export default {
  name: "DownlineRepPlans",
  components: {
    DownLineRepPlanSelectCardList
  },
  data: () => ({
    repId: null,
    breadcrumbItems: []
  }),
  methods: {
    getBreadCrumbs() {
      this.breadcrumbItems = [
        {
          title: "Downline Reps",
          path: {
            name: "DownlineRepList",
          },
          active: false,
        },
        {
          title: "View / Add Plans",
          active: true,
        },
      ];
    }
  },
  created() {
    this.repId = parseInt(atob(this.$route.params.downlineRepId));
    this.getBreadCrumbs();
  },
};
</script>
<style src="@/assets/css/downline-reps.css"></style>
